<template>
  <page-content title="设备详情">

    <div class="device-name" slot="title-extra" @click="editDevice">{{ device.nickname }}
      <a-icon type="edit"/>
    </div>


    <a-card class="card">

      <a-descriptions slot="title" :column="3" :bordered="false" size="small" class="device-info">
        <a-descriptions-item label="设备状态">
          <a-badge v-if="!device.online" status="default" text="离线"/>
          <a-badge v-else-if="device.alarm" status="processing" color="red" text="报警"/>
          <a-badge v-else status="processing" color="blue" text="在线"/>
        </a-descriptions-item>
        <a-descriptions-item label="机身编码">{{ device.mac }}</a-descriptions-item>
        <a-descriptions-item v-if="device.online" label="运行时间">{{ runningTime }}</a-descriptions-item>
        <a-descriptions-item v-else label="离线时间">{{ device.lastOfflineTime }}</a-descriptions-item>
        <a-descriptions-item label="授权账号">{{ device.vendorName || "无" }}</a-descriptions-item>
        <a-descriptions-item label="地址">{{ device.address }}</a-descriptions-item>
        <a-descriptions-item v-hasPermission="['device:add']" label="连接方式">
          <template v-if="device.platform=='GIZWITS'">机智云</template>
          <template v-else-if="device.platform=='EMQTT'">MQTT &nbsp;&nbsp;&nbsp;<a-button @click="showMqttInfo"
                                                                                          size="small">查看接入信息
          </a-button>
          </template>
        </a-descriptions-item>


      </a-descriptions>


      <div style="display: flex;justify-content: space-around">
        <WaterAction name="设备参数" :icon="require('@/assets/hillbo/ic_device_info.png')"
                     @click="clickParaSetting"></WaterAction>
        <WaterAction name="告警区间" :icon="require('@/assets/hillbo/ic_threshold.png')"
                     @click="clickThresholdSetting"></WaterAction>
        <WaterAction name="水质标准" :icon="require('@/assets/hillbo/ic_device_standard.png')"
                     @click="clickStandard"></WaterAction>
        <WaterAction name="大屏设置" :icon="require('@/assets/hillbo/ic_screen_set.png')"
                     @click="clickScreenSetting"></WaterAction>
        <WaterAction name="大屏展示" :icon="require('@/assets/hillbo/ic_screen.png')"
                     @click="clickScreenShow"></WaterAction>
        <WaterAction name="视频监控" :icon="require('@/assets/hillbo/ic_camera.png')" @click="clickCamera"></WaterAction>
        <WaterAction name="三点校正" :icon="require('@/assets/hillbo/ic_adjust.png')" @click="clickAdjust"></WaterAction>
        <WaterAction name="历史数据" :icon="require('@/assets/hillbo/ic_history_data.png')"
                     @click="clickHistory"></WaterAction>
        <WaterAction name="加药记录" :icon="require('@/assets/hillbo/ic_medical.png')"
                     @click="clickMedicalRecord"></WaterAction>
        <WaterAction name="异常记录" :icon="require('@/assets/hillbo/ic_alarm.png')"
                     @click="clickAlarmRecord"></WaterAction>
        <WaterAction name="上下线记录" :icon="require('@/assets/hillbo/ic_device_connect.png')"
                     @click="clickOnlineRecord"></WaterAction>
        <WaterAction name="二维码" :icon="require('@/assets/hillbo/ic_qrcode.png')" @click="clickQRCode"></WaterAction>
      </div>
    </a-card>


    <a-card class="card" title="设备参数" size="small">
      <a-button slot="extra" @click="clickExportReport">导出检测报告</a-button>
      <a-row :gutter="[16,16]">
        <a-col :span="6" v-for="item in paraArray">
          <WaterParaView :model="item" :valueMap="valueMap"></WaterParaView>
        </a-col>
      </a-row>
    </a-card>

    <a-card class="card" title="加药泵工作状态" size="small">
      <a-button slot="extra" style="opacity: 0">导出报告</a-button>
      <a-row :gutter="[16,16]">
        <a-col :span="6" v-for="item in switchArray">
          <WaterSwitchView :model="item" :valueMap="valueMap" @control="control"></WaterSwitchView>
        </a-col>
      </a-row>
    </a-card>

    <a-card class="card" title="数据曲线" size="small" style="background: #FFF">
      <a-space class="curve-action" slot="extra">
        <RangeDatePicker @change="changeChartTime"></RangeDatePicker>
        <a-button @click="clickExportCurve">导出Excel</a-button>
      </a-space>

      <WaterChartView ref="phChart" name="PH" v-if="paraIdentifierArray.indexOf('reg5')>=0"></WaterChartView>
      <WaterChartView ref="orpChart" name="ORP" v-if="paraIdentifierArray.indexOf('reg4')>=0"></WaterChartView>
      <WaterChartView ref="clChart" name="余氯" v-if="paraIdentifierArray.indexOf('reg6')>=0"></WaterChartView>
      <WaterChartView ref="tempChart" name="温度" v-if="paraIdentifierArray.indexOf('reg3')>=0"></WaterChartView>
      <WaterChartView ref="tuChart" name="浊度" v-if="paraIdentifierArray.indexOf('reg7')>=0"></WaterChartView>
      <div style="clear:both"></div>
    </a-card>

    <DeviceStandardSetting ref="deviceStandardSetting" :value="standardValue"
                           @change="changeStandard"></DeviceStandardSetting>
    <DeviceInfoSetting ref="deviceParaSetting" :id="device.id"></DeviceInfoSetting>
    <DeviceScreenSetting ref="deviceScreenSetting" :id="device.id"></DeviceScreenSetting>
    <DeviceThresholdSetting ref="deviceThresholdSetting" :id="device.id" @success="refresh"></DeviceThresholdSetting>
    <QrCodeModal ref="qrCodeModal" :content="'Hillbo:'+device.iotId"></QrCodeModal>

    <DeviceEdit ref="deviceEdit" @success="getDeviceInfo"></DeviceEdit>
    <MqttInfo ref="mqttInfo"></MqttInfo>

  </page-content>
</template>
<script>
import BaseDeviceView from "./BaseDeviceView";

import DeviceInfoSetting from "./setting/DeviceInfoSetting";
import DeviceThresholdSetting from "./setting/DeviceThresholdSetting";
import DeviceStandardSetting from "./setting/DeviceStandardSetting";
import DeviceScreenSetting from "./setting/DeviceScreenSetting";
import QrCodeModal from "./_components/QrCodeModal";
import MqttInfo from "./_components/MqttInfo"

import WaterAction from "./_components/WaterAction";
import WaterParaView from "./_components/WaterParaView";
import WaterSwitchView from "./_components/WaterSwitchView";
import WaterChartView from "./_components/WaterChartView";

import DeviceEdit from "./DeviceEdit";

export default {
  extends: BaseDeviceView,
  components: {
    DeviceEdit,
    WaterAction, MqttInfo,
    DeviceInfoSetting, DeviceScreenSetting, DeviceThresholdSetting, QrCodeModal, DeviceStandardSetting,
    WaterParaView, WaterSwitchView, WaterChartView
  },
  data() {
    return {
      chartTimeFrom: 0,
      chartTimeTo: 0,
      paraArray: [],
      switchArray: [],
      paraIdentifierArray: []
    };
  },
  computed: {
    runningTime() {
      let ms = new Date().getTime() - new Date(this.device.lastOnlineTime).getTime()
      let minute = parseInt(ms / 1000 / 60)
      if (minute == 0) {
        return "1分钟"
      }
      let day = parseInt(minute / 1440)
      let hour = parseInt(minute / 60) % 24
      minute = minute % 60
      let result = ""
      if (day > 0) {
        result += day + "天"
      }
      if (hour > 0) {
        result += hour + "小时"
      }
      if (minute > 0) {
        result += minute + "分钟"
      }
      return result
    },
    standardValue() {
      return this.valueMap["reg49"] || 0
    }
  },
  created() {

    //获取绑定了设备的用户
    // this.$get(`device/${iotId}/users`, {}).then(r => {
    //     this.userInfo.dataSource = r.data;
    // });
    this.refresh()
  },

  methods: {
    refresh() {
      //获取设备信息
      this.getDeviceInfo()

      //获取参数和开关设置
      this.$get(`/web/iot/water/device/paras/${this.deviceId}`).then(r => {
        this.paraArray = r.data.paraArray
        this.paraIdentifierArray = []
        this.paraArray.forEach(item => {
          this.paraIdentifierArray.push(item.identifier)
        })

        let readArray = ["reg59", "reg60", "reg61", "reg62", "reg63", "reg64", "reg65", "reg66", "reg67", "reg68", "reg69", "reg70"]
        let writeArray = ["reg37", "reg38", "reg39", "reg40", "reg41", "reg42", "reg43", "reg44", "reg45", "reg46", "reg47", "reg48"]
        let modeReadArray = ["reg63", "reg64", "reg65", "reg66"]
        let modeWriteArray = ["reg45", "reg46", "reg47", "reg48"]
        for (let i = 0; i < r.data.switchArray.length; i++) {
          let model = r.data.switchArray[i]
          model.identifier = writeArray[i]
          model.identifierRead = readArray[i]
          model.modeRead = modeReadArray[i]
          model.modeWrite = modeWriteArray[i]
        }
        this.switchArray = r.data.switchArray

        //获取曲线数据
        this.getCurveData()
      })
    },
    changeChartTime(timeFrom, timeTo) {
      this.chartTimeFrom = timeFrom
      this.chartTimeTo = timeTo
      this.getCurveData()
    },
    getCurveData() {

      let timeTo = this.chartTimeTo || parseInt(new Date().getTime() / 1000)
      let timeFrom = this.chartTimeFrom || (timeTo - 24 * 3600)

      this.$get(`/web/iot/water/history/curve`, {
        deviceId: this.deviceId,
        pageSize: 1000,
        timeFrom,
        timeTo
      }).then(suc => {
        let paras = {}
        this.paraArray.forEach(item => {
          paras[item.identifier] = item
        })
        let phData = paras.reg5 || {}
        let orpData = paras.reg4 || {}
        let clData = paras.reg6 || {}
        let tempData = paras.reg3 || {}
        let tuData = paras.reg7 || {}
        this.$refs.phChart && this.$refs.phChart.setData(suc.data[0].list, phData.min, phData.max)
        this.$refs.orpChart && this.$refs.orpChart.setData(suc.data[1].list, orpData.min, orpData.max)
        this.$refs.clChart && this.$refs.clChart.setData(suc.data[2].list, clData.min, clData.max)
        this.$refs.tempChart && this.$refs.tempChart.setData(suc.data[3].list, tempData.min, tempData.max)
        this.$refs.tuChart && this.$refs.tuChart.setData(suc.data[4].list, tuData.min, tuData.max)
      })
    },
    clickParaSetting() {
      this.$refs.deviceParaSetting.show()
    },
    clickScreenSetting() {
      this.$refs.deviceScreenSetting.show()
    },
    clickStandard() {
      this.$refs.deviceStandardSetting.show()
    },
    clickThresholdSetting() {
      this.$refs.deviceThresholdSetting.show()
    },
    clickScreenShow() {
      this.$router.push(`/device/screen/${this.device.id}`)
    },
    clickCamera() {
      this.$router.push(`/device/camera/${this.device.id}`)
    },
    clickHistory() {
      this.$router.push(`/device/history/${this.device.id}`)
    },
    clickMedicalRecord() {
      this.$router.push(`/device/medical/${this.device.id}`)
    },
    clickAdjust() {
      this.$router.push(`/device/adjust/${this.device.id}`)
    },
    clickAlarmRecord() {
      this.$router.push(`/device/alarm/${this.device.id}`)
    },
    clickOnlineRecord() {
      this.$router.push(`/device/online/${this.device.id}`)
    },
    clickQRCode() {
      this.$refs.qrCodeModal.show()
    },
    changeStandard(index) {
      this.control("reg49", index)
    },
    clickExportCurve() {
      let params = {
        deviceId: this.deviceId,
        pageNum: 1,
        pageSize: 100000,
        timeFrom: this.chartTimeFrom,
        timeTo: this.chartTimeTo
      }
      this.$export('web/iot/water/history/excel', params, "历史数据.xlsx")
    },
    clickExportReport() {
      let params = {
        deviceId: this.deviceId
      }
      this.$export('web/iot/water/device/exportReport', params, "水质检测报告.doc")
    },
    editDevice() {
      this.$refs.deviceEdit.show(this.device)
    },
    showMqttInfo() {
      this.$refs.mqttInfo.show(this.device)
    }

  }
};
</script>
<style lang="less" scoped>
.device-name {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  cursor: pointer;
}

.device-name:hover {
  opacity: 0.5;
}

.device-info {
  margin-bottom: -8px;
}

.card {
  margin-bottom: 20px;
  background: #FCFCFC;
}

.curve-action {
  display: flex;
  align-items: center;
}

</style>
