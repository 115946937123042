<template>
  <common-form
    ref="form"
    url="web/iot/water/screen"
    title="设备大屏设置"
    :edit="false"
    :items="items"
    :beforeRequest="beforeRequest"
    @success="$emit('success')">
    <template slot="params">

      <a-table :columns="paraColumns" :data-source="paramsArray" bordered size="middle" :pagination="false">

        <template slot="value" slot-scope="text, record, index">
          <a-input v-model="record.value"></a-input>
        </template>

        <template slot="min" slot-scope="text, record, index">
          <a-input v-model="record.min"></a-input>
        </template>

        <template slot="max" slot-scope="text, record, index">
          <a-input v-model="record.max"></a-input>
        </template>

      </a-table>

    </template>
  </common-form>
</template>
<script>
  import WaterParaUtil from "../WaterParaUtil"

  export default {
    props:["id"],
    data() {
      return {
        edit: false,
        paramsArray:WaterParaUtil.screenParaArray(),
        paraColumns: [
          {
            title: '参数',
            dataIndex: 'name',
            width:100
          },
          {
            title: '演示数值',
            dataIndex: 'value',
            scopedSlots: {customRender: 'value'},
          },
          {
            title: '最小值',
            dataIndex: 'min',
            scopedSlots: {customRender: 'min'},
          }, {
            title: '最大值',
            dataIndex: 'max',
            scopedSlots: {customRender: 'max'},
          }

        ]
      }
    },
    computed: {
      items() {
        return [
          {
            type: "select",
            name: "大屏模板",
            key: "type",
            options:[
              {
                value:0,name:"V1"
              },
              {
                value:1,name:"V2"
              }
            ]
          },
          {
            type: "input",
            name: "大屏标题",
            key: "title",
          },
          {
            type: "textarea",
            name: "公告栏内容",
            key: "notice",
          },
          {
            type: "image",
            name: "背景图片",
            key: "background",
          },
          {
            type: "color",
            name: "文字颜色",
            key: "textColor",
          },
          {
            type: "slot",
            name: "参数设置",
            slot: "params",
          },
        ]
      }
    },
    methods: {
      show() {
        // this.$refs.form.show(model)

        this.$get(`web/iot/water/screen/${this.id}`).then(suc=>{
          let model = suc.data
          if(model){
            this.$refs.form.show(suc.data)
            this.paramsArray = JSON.parse(model.parasJson)
          }else{
            this.$refs.form.show({})
          }

        })
      },
      beforeRequest(model){
        return {
          ...model,
          deviceId:this.id,
          parasJson:JSON.stringify(this.paramsArray)
        }
      }

    }

  }
</script>

